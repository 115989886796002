import React, { Component } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";

export default class Subscribe extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", message: "" };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    addToMailchimp(this.state.email, { FNAME: "Subscriber", LNAME: "VLM" }) // listFields are optional if you are only capturing the email address.
      .then((data) => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        console.log(this.state.email);
        console.log(data);
        this.setState({
          message: "Please confirm your email to finish subscribing :)",
          email: "",
        });
        console.log(this.state.message);
      });

    //this.setState(e.target.value);
    //console.log(this.state.email);
  }

  handleChange(event) {
    this.setState({ email: event.target.value });
  }

  componentDidMount() {
    var elems = document.querySelectorAll(".tooltipped");
    var elems2 = document.querySelectorAll(".modal");
    const M = window.M;
    var instances = M.Tooltip.init(elems);
    var instances2 = M.Modal.init(elems2);
    console.log("new script was loaded");
  }

  render() {
    return (
      <div>
        <div id="modal1" class="modal">
          <div class="modal-content">
            <form id="subscribe-blog" onSubmit={this.handleSubmit}>
              <input
                id="newsletter"
                type="email"
                value={this.state.email}
                placeholder="Enter your email address"
                onChange={this.handleChange}
              />

              <button
                class="btn waves-effect waves-light grey darken-4"
                type="submit"
                name="action"
              >
                subscribe!
              </button>
            </form>
            <p class="green-text">{this.state.message}</p>
          </div>
          {/*
          <div class="modal-content">
            <h4>Modal Header</h4>
            <p>A bunch of text</p>
          </div>
          <div class="modal-footer">
            <a href="#!" class="modal-close waves-effect waves-green btn-flat">
              Agree
            </a>
          </div>*/}
        </div>
        <button
          class="btn waves-effect waves-light grey darken-4 modal-trigger"
          data-target="modal1"
        >
          subscribe
          <i class="material-icons right">send</i>
        </button>
        <button class="btn waves-effect waves-light grey darken-4">
          <a href="mailto:hl2936@columbia.edu?Subject=Question">
            ask a question<i class="material-icons right">lightbulb_outline</i>
          </a>
        </button>

        <button class="btn waves-effect waves-light grey darken-4">
          <a href="https://twitter.com/VeryLightMatter">
            follow me on{"   "}
            <i class="fab fa-twitter" />
          </a>
        </button>

        {/*  <form id="subscribe-blog" onSubmit={this.handleSubmit}>
          <input
            id="newsletter"
            type="email"
            style={{ width: "50%" }}
            value={this.state.email}
            placeholder="Enter your email address"
            onChange={this.handleChange}
          />

          <button
            class="btn waves-effect waves-light grey darken-4"
            type="submit"
            name="action"
          >
            subscribe
            <i class="material-icons right">send</i>
          </button>
          {"    "}
          <button class="btn waves-effect waves-light grey darken-4">
            <a href="mailto:hl2936@columbia.edu?Subject=Question">
              ask a question
            </a>
            <i class="material-icons right">lightbulb_outline</i>
          </button>
        </form>
        <p class="green-text">{this.state.message}</p>


        <a
          href="https://twitter.com/VeryLightMatter"
          className="tooltipped btn-floating btn-small grey darken-4"
          data-tooltip="Twitter"
          data-position="top"
        >
          <i class="fab fa-twitter" />
        </a>*/}
        {/*
       
        <a
          href="https://medium.com/@garylai_34633"
          className="tooltipped btn-floating btn-small grey darken-4"
          data-tooltip="Medium"
          data-position="top"
        >
          <i class="fab fa-medium" />
        </a>
        <a
          href="https://www.instagram.com/garys_notebook"
          className="tooltipped btn-floating btn-small grey darken-4"
          data-tooltip="Instagram"
          data-position="top"
        >
          <i class="fab fa-instagram" />
        </a> */}
      </div>
    );
  }
}
